
import {HTTP_VERBS} from '../constants';

import MeetingInfoUtil from './utilv2';

/**
   * @class MeetingInfo
   */
export default class MeetingInfoV2 {
  /**
     *
     * @param {WebexSDK} webex
     */
  constructor(webex) {
    this.webex = webex;
  }

  /**
     * converts hydra id into conversation url and persons Id
     * @param {String} destination one of many different types of destinations to look up info for
     * @param {String} [type] to match up with the destination value
     * @returns {Promise} returns destination and type
     * @public
     * @memberof MeetingInfo
     */
  fetchInfoOptions(destination, type = null) {
    return MeetingInfoUtil.getDestinationType({
      destination,
      type,
      webex: this.webex
    });
  }

  /**
     * Fetches meeting info from the server
     * @param {String} destination one of many different types of destinations to look up info for
     * @param {String} [type] to match up with the destination value
     * @returns {Promise} returns a meeting info object
     * @public
     * @memberof MeetingInfo
     */
  async fetchMeetingInfo(destination, type = null) {
    const destinationType = await MeetingInfoUtil.getDestinationType({
      destination,
      type,
      webex: this.webex
    });
    const body = await MeetingInfoUtil.getRequestBody(destinationType);

    return this.webex.request({
      method: HTTP_VERBS.POST,
      service: 'webex-appapi-service',
      resource: 'meetingInfo',
      body
    });
  }
}

