import React from 'react';

export default function WebexLogo() {
  return (
    <svg width="102" height="33" viewBox="0 0 102 33" fill="currentColor" xmlns="http://www.w3.org/2000/svg" alt="Webex by Cisco">
      <path d="M48.7863 8.02319H51.4715L47.5674 19.824H44.487L41.5692 10.9189L38.6513 19.824H35.6189L31.7148 8.02319H34.4628L37.233 16.9911L40.1398 8.02319H43.0576L46.0125 17.0391L48.7863 8.02319Z" />
      <path d="M62.9891 14.871H54.2798C54.3602 15.4795 54.5884 16.0591 54.9446 16.5589C55.2601 16.9963 55.6875 17.3408 56.1819 17.5562C56.6855 17.7737 57.2291 17.8831 57.7775 17.8775C58.3855 17.8752 58.9883 17.7652 59.5578 17.5525C60.1483 17.3366 60.6997 17.0259 61.1904 16.6328L62.472 18.4796C61.8184 19.0494 61.0574 19.4827 60.2337 19.7538C59.3878 20.0194 58.5053 20.1502 57.6187 20.1416C56.5267 20.1632 55.4481 19.8983 54.4903 19.3734C53.5843 18.8657 52.8423 18.1096 52.3518 17.1942C51.824 16.1909 51.5597 15.0699 51.5835 13.9365C51.5609 12.8138 51.8145 11.7027 52.3222 10.701C52.7939 9.78991 53.5135 9.03066 54.398 8.51075C55.3259 7.97668 56.382 7.70595 57.4525 7.72772C58.4627 7.69977 59.4595 7.96363 60.3235 8.48769C61.1876 9.01175 61.8822 9.77379 62.3243 10.6825C62.8123 11.6875 63.0553 12.7937 63.0334 13.9107C63.0371 14.1581 63.0223 14.4795 62.9891 14.871ZM60.4036 12.9134C60.3242 12.1047 59.9772 11.3456 59.4175 10.7564C59.1491 10.5049 58.833 10.31 58.4878 10.1831C58.1427 10.0562 57.7755 9.99992 57.4082 10.0177C57.0215 10 56.6353 10.0602 56.2723 10.1947C55.9094 10.3292 55.5772 10.5353 55.2955 10.8007C54.7377 11.3812 54.3815 12.1259 54.2798 12.9245L60.4036 12.9134Z" />
      <path d="M74.0053 8.51812C74.9038 9.04957 75.6405 9.81569 76.1365 10.7342C76.6668 11.7132 76.9364 12.8122 76.9195 13.9254C76.9365 15.0375 76.6668 16.1353 76.1365 17.1129C75.6404 18.0357 74.9009 18.8049 73.9984 19.3369C73.0959 19.8689 72.0648 20.1434 71.0173 20.1306C70.2821 20.146 69.5531 19.9931 68.8861 19.6836C68.2748 19.3888 67.74 18.9565 67.3237 18.4205V19.824H64.7383V3H67.3237V9.42673C67.7418 8.89431 68.2763 8.46473 68.8861 8.17093C69.5523 7.85843 70.2816 7.70421 71.0173 7.72032C72.0676 7.70747 73.1012 7.98345 74.0053 8.51812ZM72.5944 17.2016C73.1307 16.8719 73.5623 16.3967 73.8391 15.8313C74.1232 15.2355 74.2707 14.5837 74.2707 13.9236C74.2707 13.2635 74.1232 12.6117 73.8391 12.0159C73.5612 11.4513 73.1298 10.9764 72.5944 10.6456C72.0253 10.32 71.3811 10.1488 70.7255 10.1488C70.0699 10.1488 69.4256 10.32 68.8566 10.6456C68.3198 10.9715 67.8848 11.441 67.6008 12.0011C67.2935 12.5993 67.1388 13.2641 67.1502 13.9365C67.1384 14.6078 67.2933 15.2714 67.6008 15.8682C67.8848 16.4283 68.3198 16.8978 68.8566 17.2237C69.4256 17.5493 70.0699 17.7206 70.7255 17.7206C71.3811 17.7206 72.0253 17.5493 72.5944 17.2237V17.2016Z" />
      <path d="M89.6765 14.871H80.9709C81.0512 15.4795 81.2795 16.0591 81.6357 16.559C81.9512 16.9963 82.3786 17.3408 82.873 17.5562C83.3766 17.7737 83.9202 17.8832 84.4686 17.8775C85.0766 17.8747 85.6792 17.7647 86.2489 17.5525C86.8394 17.3366 87.3908 17.026 87.8815 16.6328L89.1594 18.4796C88.5077 19.0502 87.7477 19.4836 86.9248 19.7539C86.0776 20.0192 85.1939 20.15 84.3061 20.1417C83.2143 20.162 82.136 19.8972 81.1777 19.3734C80.2736 18.8645 79.5331 18.1086 79.0429 17.1942C78.514 16.1912 78.2484 15.0703 78.2709 13.9366C78.2484 12.8138 78.5021 11.7028 79.0096 10.701C79.4813 9.78994 80.2009 9.03069 81.0854 8.51077C82.0187 7.96891 83.0831 7.69419 84.1621 7.71667C85.172 7.69009 86.1682 7.95452 87.032 8.47843C87.8958 9.00233 88.5907 9.76361 89.0338 10.6715C89.5218 11.6764 89.7649 12.7827 89.743 13.8996C89.7282 14.1582 89.7024 14.4795 89.6765 14.871ZM87.091 12.9135C87.0058 12.1099 86.6579 11.3569 86.1012 10.7712C85.8347 10.5183 85.5203 10.3214 85.1764 10.192C84.8326 10.0626 84.4664 10.0033 84.0993 10.0177C83.7126 9.99961 83.3262 10.0596 82.9632 10.1941C82.6002 10.3287 82.2681 10.535 81.9866 10.8008C81.4274 11.3803 81.0711 12.1255 80.9709 12.9245L87.091 12.9135Z" />
      <path d="M101.958 19.824H98.7923L95.8005 15.7205L92.8457 19.824H89.8281L94.2603 13.7999L89.8983 8.02319H93.0673L95.9483 11.9642L98.8292 8.02319H101.891L97.5106 13.7777L101.958 19.824Z" />
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.39397 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint0_linear)"/>
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.39397 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint1_radial)"/>
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.39397 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint2_radial)"/>
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.39397 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint3_radial)"/>
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.39397 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint4_radial)"/>
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.39397 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint5_radial)"/>
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.39397 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint6_radial)"/>
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.39397 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint7_linear)"/>
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.39397 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint8_radial)"/>
      <path d="M17.8018 3C15.9264 3 13.7554 3.62177 12.3187 6.29377C11.4929 7.82965 11.0746 9.55872 10.6877 11.2746C10.5603 11.8393 10.4289 12.4107 10.2891 12.9526V20.7161C10.9987 20.5197 11.7612 20.1419 12.4926 19.4148C15.2398 16.684 15.7005 9.53912 17.0744 8.17337C17.2301 8.0186 17.3984 7.9286 17.5801 7.9286C18.1329 7.9286 18.5604 8.61395 18.9698 9.4382C19.4249 10.3544 19.9656 11.3997 21.5607 11.3997C22.29 11.3997 23.9726 10.885 23.9726 8.86738C23.9726 7.66003 21.9144 3 17.8018 3Z" fill="url(#paint9_radial)"/>
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.39397 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint10_radial)"/>
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.39397 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint11_radial)"/>
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.39397 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint12_radial)"/>
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.39397 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint13_radial)"/>
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.39397 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint14_radial)"/>
      <path d="M23.214 5.74961C23.0286 5.74961 22.8422 5.76673 22.6593 5.80194C23.5286 7.02795 23.9636 8.31231 23.9636 8.86724C23.9636 10.8849 22.2809 11.3996 21.5517 11.3996C20.9825 11.3996 20.5477 11.2664 20.2031 11.0536C20.1998 11.0662 20.1967 11.0788 20.1935 11.0914C20.091 11.4919 20.0101 11.9301 19.87 12.3566C19.6008 13.1767 19.2376 14.1134 18.8498 14.8383C18.4318 15.6197 18.0179 16.0769 17.5245 16.0529C16.9635 16.0256 16.5087 15.3184 15.9433 13.3195C15.761 12.6751 15.5922 11.9684 15.4357 11.2746C15.0476 9.55363 14.5713 7.82304 13.7568 6.29377C12.4265 3.79609 10.1571 3 8.34542 3C6.66524 3 5.40994 3.77801 4.42384 4.75823C4.15133 5.02913 3.85366 5.39409 3.55469 5.82299C4.41476 6.02563 5.22803 6.65881 5.3579 7.85882C5.38348 8.08762 5.56303 9.90486 6.16603 12.0107C6.33906 11.4827 6.59134 10.7009 7.17744 9.43821C7.46469 8.85996 7.72389 8.47867 7.95778 8.24618C8.17228 8.03297 8.36545 7.94491 8.53934 7.94491C8.72691 7.94491 9.36735 8.11282 9.99084 10.2527C10.7641 12.906 11.3395 17.1289 13.5319 19.3711C14.4989 20.3601 15.8521 21 17.7276 21C19.3732 21 20.7452 20.2912 21.7234 19.3052C23.3318 17.6838 24.2131 15.0031 24.5275 14.0111C25.422 11.189 25.6726 8.63656 25.7018 8.3665C25.8682 6.8234 24.5391 5.74961 23.214 5.74961Z" fill="#316AFF"/>
      <path d="M23.214 5.74961C23.0286 5.74961 22.8422 5.76673 22.6593 5.80194C23.5286 7.02795 23.9636 8.31231 23.9636 8.86724C23.9636 10.8849 22.2809 11.3996 21.5517 11.3996C20.9825 11.3996 20.5477 11.2664 20.2031 11.0536C20.1998 11.0662 20.1967 11.0788 20.1935 11.0914C20.091 11.4919 20.0101 11.9301 19.87 12.3566C19.6008 13.1767 19.2376 14.1134 18.8498 14.8383C18.4318 15.6197 18.0179 16.0769 17.5245 16.0529C16.9635 16.0256 16.5087 15.3184 15.9433 13.3195C15.761 12.6751 15.5922 11.9684 15.4357 11.2746C15.0476 9.55363 14.5713 7.82304 13.7568 6.29377C12.4265 3.79609 10.1571 3 8.34542 3C6.66524 3 5.40994 3.77801 4.42384 4.75823C4.15133 5.02913 3.85366 5.39409 3.55469 5.82299C4.41476 6.02563 5.22803 6.65881 5.3579 7.85882C5.38348 8.08762 5.56303 9.90486 6.16603 12.0107C6.33906 11.4827 6.59134 10.7009 7.17744 9.43821C7.46469 8.85996 7.72389 8.47867 7.95778 8.24618C8.17228 8.03297 8.36545 7.94491 8.53934 7.94491C8.72691 7.94491 9.36735 8.11282 9.99084 10.2527C10.7641 12.906 11.3395 17.1289 13.5319 19.3711C14.4989 20.3601 15.8521 21 17.7276 21C19.3732 21 20.7452 20.2912 21.7234 19.3052C23.3318 17.6838 24.2131 15.0031 24.5275 14.0111C25.422 11.189 25.6726 8.63656 25.7018 8.3665C25.8682 6.8234 24.5391 5.74961 23.214 5.74961Z" fill="url(#paint15_radial)"/>
      <path d="M23.214 5.74961C23.0286 5.74961 22.8422 5.76673 22.6593 5.80194C23.5286 7.02795 23.9636 8.31231 23.9636 8.86724C23.9636 10.8849 22.2809 11.3996 21.5517 11.3996C20.9825 11.3996 20.5477 11.2664 20.2031 11.0536C20.1998 11.0662 20.1967 11.0788 20.1935 11.0914C20.091 11.4919 20.0101 11.9301 19.87 12.3566C19.6008 13.1767 19.2376 14.1134 18.8498 14.8383C18.4318 15.6197 18.0179 16.0769 17.5245 16.0529C16.9635 16.0256 16.5087 15.3184 15.9433 13.3195C15.761 12.6751 15.5922 11.9684 15.4357 11.2746C15.0476 9.55363 14.5713 7.82304 13.7568 6.29377C12.4265 3.79609 10.1571 3 8.34542 3C6.66524 3 5.40994 3.77801 4.42384 4.75823C4.15133 5.02913 3.85366 5.39409 3.55469 5.82299C4.41476 6.02563 5.22803 6.65881 5.3579 7.85882C5.38348 8.08762 5.56303 9.90486 6.16603 12.0107C6.33906 11.4827 6.59134 10.7009 7.17744 9.43821C7.46469 8.85996 7.72389 8.47867 7.95778 8.24618C8.17228 8.03297 8.36545 7.94491 8.53934 7.94491C8.72691 7.94491 9.36735 8.11282 9.99084 10.2527C10.7641 12.906 11.3395 17.1289 13.5319 19.3711C14.4989 20.3601 15.8521 21 17.7276 21C19.3732 21 20.7452 20.2912 21.7234 19.3052C23.3318 17.6838 24.2131 15.0031 24.5275 14.0111C25.422 11.189 25.6726 8.63656 25.7018 8.3665C25.8682 6.8234 24.5391 5.74961 23.214 5.74961Z" fill="url(#paint16_radial)"/>
      <path d="M23.214 5.74961C23.0286 5.74961 22.8422 5.76673 22.6593 5.80194C23.5286 7.02795 23.9636 8.31231 23.9636 8.86724C23.9636 10.8849 22.2809 11.3996 21.5517 11.3996C20.9825 11.3996 20.5477 11.2664 20.2031 11.0536C20.1998 11.0662 20.1967 11.0788 20.1935 11.0914C20.091 11.4919 20.0101 11.9301 19.87 12.3566C19.6008 13.1767 19.2376 14.1134 18.8498 14.8383C18.4318 15.6197 18.0179 16.0769 17.5245 16.0529C16.9635 16.0256 16.5087 15.3184 15.9433 13.3195C15.761 12.6751 15.5922 11.9684 15.4357 11.2746C15.0476 9.55363 14.5713 7.82304 13.7568 6.29377C12.4265 3.79609 10.1571 3 8.34542 3C6.66524 3 5.40994 3.77801 4.42384 4.75823C4.15133 5.02913 3.85366 5.39409 3.55469 5.82299C4.41476 6.02563 5.22803 6.65881 5.3579 7.85882C5.38348 8.08762 5.56303 9.90486 6.16603 12.0107C6.33906 11.4827 6.59134 10.7009 7.17744 9.43821C7.46469 8.85996 7.72389 8.47867 7.95778 8.24618C8.17228 8.03297 8.36545 7.94491 8.53934 7.94491C8.72691 7.94491 9.36735 8.11282 9.99084 10.2527C10.7641 12.906 11.3395 17.1289 13.5319 19.3711C14.4989 20.3601 15.8521 21 17.7276 21C19.3732 21 20.7452 20.2912 21.7234 19.3052C23.3318 17.6838 24.2131 15.0031 24.5275 14.0111C25.422 11.189 25.6726 8.63656 25.7018 8.3665C25.8682 6.8234 24.5391 5.74961 23.214 5.74961Z" fill="url(#paint17_radial)"/>
      <path d="M23.214 5.74961C23.0286 5.74961 22.8422 5.76673 22.6593 5.80194C23.5286 7.02795 23.9636 8.31231 23.9636 8.86724C23.9636 10.8849 22.2809 11.3996 21.5517 11.3996C20.9825 11.3996 20.5477 11.2664 20.2031 11.0536C20.1998 11.0662 20.1967 11.0788 20.1935 11.0914C20.091 11.4919 20.0101 11.9301 19.87 12.3566C19.6008 13.1767 19.2376 14.1134 18.8498 14.8383C18.4318 15.6197 18.0179 16.0769 17.5245 16.0529C16.9635 16.0256 16.5087 15.3184 15.9433 13.3195C15.761 12.6751 15.5922 11.9684 15.4357 11.2746C15.0476 9.55363 14.5713 7.82304 13.7568 6.29377C12.4265 3.79609 10.1571 3 8.34542 3C6.66524 3 5.40994 3.77801 4.42384 4.75823C4.15133 5.02913 3.85366 5.39409 3.55469 5.82299C4.41476 6.02563 5.22803 6.65881 5.3579 7.85882C5.38348 8.08762 5.56303 9.90486 6.16603 12.0107C6.33906 11.4827 6.59134 10.7009 7.17744 9.43821C7.46469 8.85996 7.72389 8.47867 7.95778 8.24618C8.17228 8.03297 8.36545 7.94491 8.53934 7.94491C8.72691 7.94491 9.36735 8.11282 9.99084 10.2527C10.7641 12.906 11.3395 17.1289 13.5319 19.3711C14.4989 20.3601 15.8521 21 17.7276 21C19.3732 21 20.7452 20.2912 21.7234 19.3052C23.3318 17.6838 24.2131 15.0031 24.5275 14.0111C25.422 11.189 25.6726 8.63656 25.7018 8.3665C25.8682 6.8234 24.5391 5.74961 23.214 5.74961Z" fill="url(#paint18_radial)"/>
      <path d="M23.214 5.74961C23.0286 5.74961 22.8422 5.76673 22.6593 5.80194C23.5286 7.02795 23.9636 8.31231 23.9636 8.86724C23.9636 10.8849 22.2809 11.3996 21.5517 11.3996C20.9825 11.3996 20.5477 11.2664 20.2031 11.0536C20.1998 11.0662 20.1967 11.0788 20.1935 11.0914C20.091 11.4919 20.0101 11.9301 19.87 12.3566C19.6008 13.1767 19.2376 14.1134 18.8498 14.8383C18.4318 15.6197 18.0179 16.0769 17.5245 16.0529C16.9635 16.0256 16.5087 15.3184 15.9433 13.3195C15.761 12.6751 15.5922 11.9684 15.4357 11.2746C15.0476 9.55363 14.5713 7.82304 13.7568 6.29377C12.4265 3.79609 10.1571 3 8.34542 3C6.66524 3 5.40994 3.77801 4.42384 4.75823C4.15133 5.02913 3.85366 5.39409 3.55469 5.82299C4.41476 6.02563 5.22803 6.65881 5.3579 7.85882C5.38348 8.08762 5.56303 9.90486 6.16603 12.0107C6.33906 11.4827 6.59134 10.7009 7.17744 9.43821C7.46469 8.85996 7.72389 8.47867 7.95778 8.24618C8.17228 8.03297 8.36545 7.94491 8.53934 7.94491C8.72691 7.94491 9.36735 8.11282 9.99084 10.2527C10.7641 12.906 11.3395 17.1289 13.5319 19.3711C14.4989 20.3601 15.8521 21 17.7276 21C19.3732 21 20.7452 20.2912 21.7234 19.3052C23.3318 17.6838 24.2131 15.0031 24.5275 14.0111C25.422 11.189 25.6726 8.63656 25.7018 8.3665C25.8682 6.8234 24.5391 5.74961 23.214 5.74961Z" fill="url(#paint19_radial)"/>
      <path d="M23.214 5.74961C23.0286 5.74961 22.8422 5.76673 22.6593 5.80194C23.5286 7.02795 23.9636 8.31231 23.9636 8.86724C23.9636 10.8849 22.2809 11.3996 21.5517 11.3996C20.9825 11.3996 20.5477 11.2664 20.2031 11.0536C20.1998 11.0662 20.1967 11.0788 20.1935 11.0914C20.091 11.4919 20.0101 11.9301 19.87 12.3566C19.6008 13.1767 19.2376 14.1134 18.8498 14.8383C18.4318 15.6197 18.0179 16.0769 17.5245 16.0529C16.9635 16.0256 16.5087 15.3184 15.9433 13.3195C15.761 12.6751 15.5922 11.9684 15.4357 11.2746C15.0476 9.55363 14.5713 7.82304 13.7568 6.29377C12.4265 3.79609 10.1571 3 8.34542 3C6.66524 3 5.40994 3.77801 4.42384 4.75823C4.15133 5.02913 3.85366 5.39409 3.55469 5.82299C4.41476 6.02563 5.22803 6.65881 5.3579 7.85882C5.38348 8.08762 5.56303 9.90486 6.16603 12.0107C6.33906 11.4827 6.59134 10.7009 7.17744 9.43821C7.46469 8.85996 7.72389 8.47867 7.95778 8.24618C8.17228 8.03297 8.36545 7.94491 8.53934 7.94491C8.72691 7.94491 9.36735 8.11282 9.99084 10.2527C10.7641 12.906 11.3395 17.1289 13.5319 19.3711C14.4989 20.3601 15.8521 21 17.7276 21C19.3732 21 20.7452 20.2912 21.7234 19.3052C23.3318 17.6838 24.2131 15.0031 24.5275 14.0111C25.422 11.189 25.6726 8.63656 25.7018 8.3665C25.8682 6.8234 24.5391 5.74961 23.214 5.74961Z" fill="url(#paint20_radial)"/>
      <path d="M23.214 5.74961C23.0286 5.74961 22.8422 5.76673 22.6593 5.80194C23.5286 7.02795 23.9636 8.31231 23.9636 8.86724C23.9636 10.8849 22.2809 11.3996 21.5517 11.3996C20.9825 11.3996 20.5477 11.2664 20.2031 11.0536C20.1998 11.0662 20.1967 11.0788 20.1935 11.0914C20.091 11.4919 20.0101 11.9301 19.87 12.3566C19.6008 13.1767 19.2376 14.1134 18.8498 14.8383C18.4318 15.6197 18.0179 16.0769 17.5245 16.0529C16.9635 16.0256 16.5087 15.3184 15.9433 13.3195C15.761 12.6751 15.5922 11.9684 15.4357 11.2746C15.0476 9.55363 14.5713 7.82304 13.7568 6.29377C12.4265 3.79609 10.1571 3 8.34542 3C6.66524 3 5.40994 3.77801 4.42384 4.75823C4.15133 5.02913 3.85366 5.39409 3.55469 5.82299C4.41476 6.02563 5.22803 6.65881 5.3579 7.85882C5.38348 8.08762 5.56303 9.90486 6.16603 12.0107C6.33906 11.4827 6.59134 10.7009 7.17744 9.43821C7.46469 8.85996 7.72389 8.47867 7.95778 8.24618C8.17228 8.03297 8.36545 7.94491 8.53934 7.94491C8.72691 7.94491 9.36735 8.11282 9.99084 10.2527C10.7641 12.906 11.3395 17.1289 13.5319 19.3711C14.4989 20.3601 15.8521 21 17.7276 21C19.3732 21 20.7452 20.2912 21.7234 19.3052C23.3318 17.6838 24.2131 15.0031 24.5275 14.0111C25.422 11.189 25.6726 8.63656 25.7018 8.3665C25.8682 6.8234 24.5391 5.74961 23.214 5.74961Z" fill="url(#paint21_radial)"/>
      <path d="M23.214 5.74961C23.0286 5.74961 22.8422 5.76673 22.6593 5.80194C23.5286 7.02795 23.9636 8.31231 23.9636 8.86724C23.9636 10.8849 22.2809 11.3996 21.5517 11.3996C20.9825 11.3996 20.5477 11.2664 20.2031 11.0536C20.1998 11.0662 20.1967 11.0788 20.1935 11.0914C20.091 11.4919 20.0101 11.9301 19.87 12.3566C19.6008 13.1767 19.2376 14.1134 18.8498 14.8383C18.4318 15.6197 18.0179 16.0769 17.5245 16.0529C16.9635 16.0256 16.5087 15.3184 15.9433 13.3195C15.761 12.6751 15.5922 11.9684 15.4357 11.2746C15.0476 9.55363 14.5713 7.82304 13.7568 6.29377C12.4265 3.79609 10.1571 3 8.34542 3C6.66524 3 5.40994 3.77801 4.42384 4.75823C4.15133 5.02913 3.85366 5.39409 3.55469 5.82299C4.41476 6.02563 5.22803 6.65881 5.3579 7.85882C5.38348 8.08762 5.56303 9.90486 6.16603 12.0107C6.33906 11.4827 6.59134 10.7009 7.17744 9.43821C7.46469 8.85996 7.72389 8.47867 7.95778 8.24618C8.17228 8.03297 8.36545 7.94491 8.53934 7.94491C8.72691 7.94491 9.36735 8.11282 9.99084 10.2527C10.7641 12.906 11.3395 17.1289 13.5319 19.3711C14.4989 20.3601 15.8521 21 17.7276 21C19.3732 21 20.7452 20.2912 21.7234 19.3052C23.3318 17.6838 24.2131 15.0031 24.5275 14.0111C25.422 11.189 25.6726 8.63656 25.7018 8.3665C25.8682 6.8234 24.5391 5.74961 23.214 5.74961Z" fill="url(#paint22_radial)"/>
      <path d="M23.214 5.74961C23.0286 5.74961 22.8422 5.76673 22.6593 5.80194C23.5286 7.02795 23.9636 8.31231 23.9636 8.86724C23.9636 10.8849 22.2809 11.3996 21.5517 11.3996C20.9825 11.3996 20.5477 11.2664 20.2031 11.0536C20.1998 11.0662 20.1967 11.0788 20.1935 11.0914C20.091 11.4919 20.0101 11.9301 19.87 12.3566C19.6008 13.1767 19.2376 14.1134 18.8498 14.8383C18.4318 15.6197 18.0179 16.0769 17.5245 16.0529C16.9635 16.0256 16.5087 15.3184 15.9433 13.3195C15.761 12.6751 15.5922 11.9684 15.4357 11.2746C15.0476 9.55363 14.5713 7.82304 13.7568 6.29377C12.4265 3.79609 10.1571 3 8.34542 3C6.66524 3 5.40994 3.77801 4.42384 4.75823C4.15133 5.02913 3.85366 5.39409 3.55469 5.82299C4.41476 6.02563 5.22803 6.65881 5.3579 7.85882C5.38348 8.08762 5.56303 9.90486 6.16603 12.0107C6.33906 11.4827 6.59134 10.7009 7.17744 9.43821C7.46469 8.85996 7.72389 8.47867 7.95778 8.24618C8.17228 8.03297 8.36545 7.94491 8.53934 7.94491C8.72691 7.94491 9.36735 8.11282 9.99084 10.2527C10.7641 12.906 11.3395 17.1289 13.5319 19.3711C14.4989 20.3601 15.8521 21 17.7276 21C19.3732 21 20.7452 20.2912 21.7234 19.3052C23.3318 17.6838 24.2131 15.0031 24.5275 14.0111C25.422 11.189 25.6726 8.63656 25.7018 8.3665C25.8682 6.8234 24.5391 5.74961 23.214 5.74961Z" fill="url(#paint23_radial)"/>
      <path d="M23.214 5.74961C23.0286 5.74961 22.8422 5.76673 22.6593 5.80194C23.5286 7.02795 23.9636 8.31231 23.9636 8.86724C23.9636 10.8849 22.2809 11.3996 21.5517 11.3996C20.9825 11.3996 20.5477 11.2664 20.2031 11.0536C20.1998 11.0662 20.1967 11.0788 20.1935 11.0914C20.091 11.4919 20.0101 11.9301 19.87 12.3566C19.6008 13.1767 19.2376 14.1134 18.8498 14.8383C18.4318 15.6197 18.0179 16.0769 17.5245 16.0529C16.9635 16.0256 16.5087 15.3184 15.9433 13.3195C15.761 12.6751 15.5922 11.9684 15.4357 11.2746C15.0476 9.55363 14.5713 7.82304 13.7568 6.29377C12.4265 3.79609 10.1571 3 8.34542 3C6.66524 3 5.40994 3.77801 4.42384 4.75823C4.15133 5.02913 3.85366 5.39409 3.55469 5.82299C4.41476 6.02563 5.22803 6.65881 5.3579 7.85882C5.38348 8.08762 5.56303 9.90486 6.16603 12.0107C6.33906 11.4827 6.59134 10.7009 7.17744 9.43821C7.46469 8.85996 7.72389 8.47867 7.95778 8.24618C8.17228 8.03297 8.36545 7.94491 8.53934 7.94491C8.72691 7.94491 9.36735 8.11282 9.99084 10.2527C10.7641 12.906 11.3395 17.1289 13.5319 19.3711C14.4989 20.3601 15.8521 21 17.7276 21C19.3732 21 20.7452 20.2912 21.7234 19.3052C23.3318 17.6838 24.2131 15.0031 24.5275 14.0111C25.422 11.189 25.6726 8.63656 25.7018 8.3665C25.8682 6.8234 24.5391 5.74961 23.214 5.74961Z" fill="url(#paint24_radial)"/>
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.394 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint25_linear)"/>
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.394 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint26_radial)"/>
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.394 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint27_linear)"/>
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.394 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint28_linear)"/>
      <path d="M8.2752 20.9287C3.5932 20.9287 1.99967 15.1377 1.64261 14.0111C0.745924 11.1822 0.473333 8.63394 0.444477 8.3665C0.27797 6.8234 1.54339 5.74961 2.9322 5.74961C3.9838 5.74961 5.20424 6.36527 5.3659 7.85896C5.394 8.1102 5.60775 10.277 6.36404 12.638C7.15331 15.102 7.9906 16.0496 8.59357 16.0496C9.56728 16.0496 10.1614 13.6036 10.6866 11.2746C11.0735 9.55872 11.4918 7.82965 12.3176 6.29377C13.7543 3.62177 15.9253 3 17.8008 3C21.9133 3 23.9715 7.66003 23.9715 8.86738C23.9715 10.885 22.2889 11.3997 21.5596 11.3997C19.9645 11.3997 19.4238 10.3544 18.9687 9.43821C18.5593 8.61395 18.1318 7.9286 17.579 7.9286C17.3974 7.9286 17.229 8.0186 17.0734 8.17337C15.6994 9.53912 15.2387 16.684 12.4916 19.4148C10.9686 20.9287 9.31153 20.9287 8.2752 20.9287Z" fill="url(#paint29_radial)"/>
      <path d="M76.6975 29.1488H76.2852V25.3674H76.7903V26.7975C76.8907 26.6495 77.0275 26.5297 77.1875 26.4496C77.3475 26.3696 77.5254 26.3321 77.7041 26.3406C77.879 26.332 78.0533 26.3656 78.2124 26.4387C78.3715 26.5117 78.5106 26.6221 78.6179 26.7604C78.8204 27.0544 78.9209 27.4067 78.9039 27.7633C78.9158 27.9659 78.8872 28.1689 78.8195 28.3602C78.7519 28.5516 78.6467 28.7275 78.5102 28.8777C78.4006 28.9887 78.2689 29.0756 78.1237 29.1325C77.9784 29.1894 77.8228 29.2153 77.667 29.2083C77.4938 29.2179 77.321 29.1826 77.1655 29.1058C77.0099 29.029 76.8769 28.9133 76.7792 28.77L76.6975 29.1488ZM78.3913 27.7522C78.4004 27.6121 78.3814 27.4716 78.3355 27.3389C78.2896 27.2062 78.2176 27.0841 78.1239 26.9795C78.0535 26.9092 77.9693 26.8544 77.8765 26.8186C77.7838 26.7828 77.6846 26.7667 77.5852 26.7715C77.4693 26.7666 77.3538 26.7889 77.248 26.8366C77.1422 26.8843 77.0491 26.9562 76.9761 27.0464C76.8262 27.2504 76.7502 27.4993 76.7606 27.7522C76.7419 28.043 76.8349 28.3299 77.0206 28.5545C77.0905 28.6347 77.1775 28.698 77.2753 28.7399C77.3731 28.7817 77.479 28.8008 77.5852 28.796C77.7014 28.8022 77.8173 28.7793 77.9224 28.7293C78.0275 28.6794 78.1185 28.604 78.187 28.5099C78.3313 28.2843 78.4026 28.0197 78.3913 27.7522Z" />
      <path d="M81.6039 26.4187L80.653 29.186C80.5722 29.453 80.4466 29.7042 80.2815 29.9289C80.2166 30.006 80.1351 30.0674 80.0432 30.1086C79.9512 30.1498 79.8511 30.1696 79.7503 30.1667C79.6398 30.1647 79.5299 30.1485 79.4235 30.1184V29.7098C79.5098 29.7316 79.5982 29.744 79.6872 29.7469C79.7354 29.7494 79.7836 29.7403 79.8276 29.7202C79.8715 29.7002 79.91 29.6698 79.9398 29.6318C80.0365 29.4789 80.1126 29.3141 80.1664 29.1414H80.0029L79 26.4113H79.546L80.3187 28.7328L81.0876 26.4113L81.6039 26.4187Z" />
      <path d="M88.6453 25.3712H87.6758V29.1638H88.6341L88.6453 25.3712Z" />
      <path d="M96.3569 26.4596C96.11 26.3239 95.8326 26.2536 95.5509 26.2553C95.4128 26.2469 95.2744 26.2669 95.1444 26.314C95.0143 26.361 94.8952 26.4342 94.7945 26.5291C94.6938 26.6239 94.6135 26.7383 94.5586 26.8653C94.5038 26.9923 94.4754 27.1291 94.4754 27.2675C94.4754 27.4058 94.5038 27.5427 94.5586 27.6697C94.6135 27.7967 94.6938 27.9111 94.7945 28.0059C94.8952 28.1007 95.0143 28.1739 95.1444 28.221C95.2744 28.2681 95.4128 28.2881 95.5509 28.2797C95.8319 28.2797 96.1087 28.2108 96.3569 28.0791V29.0932C96.0743 29.1815 95.7801 29.2278 95.484 29.2306C95.219 29.2422 94.9544 29.1999 94.7062 29.1065C94.458 29.013 94.2312 28.8703 94.0397 28.6868C93.8481 28.5034 93.6956 28.2831 93.5915 28.0391C93.4873 27.7952 93.4336 27.5327 93.4336 27.2675C93.4336 27.0022 93.4873 26.7398 93.5915 26.4958C93.6956 26.2519 93.8481 26.0316 94.0397 25.8481C94.2312 25.6647 94.458 25.522 94.7062 25.4285C94.9544 25.3351 95.219 25.2928 95.484 25.3043C95.7802 25.306 96.0745 25.3523 96.3569 25.4418V26.4596Z" />
      <path d="M86.3727 26.4596C86.1276 26.323 85.851 26.2526 85.5704 26.2553C85.4323 26.2469 85.294 26.2669 85.1639 26.314C85.0338 26.3611 84.9148 26.4343 84.814 26.5291C84.7133 26.6239 84.633 26.7383 84.5781 26.8653C84.5233 26.9923 84.495 27.1292 84.495 27.2675C84.495 27.4059 84.5233 27.5427 84.5781 27.6697C84.633 27.7967 84.7133 27.9111 84.814 28.0059C84.9148 28.1008 85.0338 28.174 85.1639 28.221C85.294 28.2681 85.4323 28.2881 85.5704 28.2797C85.8491 28.2854 86.1249 28.2216 86.3727 28.094V29.1081C86.0914 29.1965 85.7985 29.2428 85.5035 29.2455C85.2385 29.257 84.974 29.2148 84.7257 29.1213C84.4775 29.0279 84.2508 28.8851 84.0592 28.7017C83.8676 28.5183 83.7151 28.298 83.611 28.054C83.5068 27.8101 83.4531 27.5476 83.4531 27.2824C83.4531 27.0171 83.5068 26.7546 83.611 26.5107C83.7151 26.2668 83.8676 26.0465 84.0592 25.863C84.2508 25.6796 84.4775 25.5369 84.7257 25.4434C84.974 25.3499 85.2385 25.3077 85.5035 25.3192C85.7986 25.3204 86.0917 25.3667 86.3727 25.4567V26.4596Z" />
      <path d="M99.3652 26.2702C99.1671 26.2687 98.973 26.3261 98.8077 26.4352C98.6423 26.5443 98.5131 26.7 98.4364 26.8827C98.3598 27.0653 98.3391 27.2667 98.3771 27.4611C98.4151 27.6555 98.5101 27.8342 98.6499 27.9746C98.7897 28.1149 98.9681 28.2105 99.1624 28.2493C99.3566 28.288 99.558 28.2681 99.741 28.1922C99.9239 28.1162 100.08 27.9875 100.19 27.8226C100.3 27.6576 100.358 27.4638 100.357 27.2657C100.359 27.1347 100.335 27.0047 100.286 26.8832C100.237 26.7618 100.164 26.6514 100.071 26.5586C99.9787 26.4658 99.8686 26.3925 99.7474 26.343C99.6261 26.2934 99.4962 26.2687 99.3652 26.2702ZM101.371 27.2657C101.379 27.6631 101.269 28.054 101.054 28.3885C100.839 28.723 100.53 28.986 100.165 29.1439C99.7998 29.3018 99.3963 29.3476 99.0053 29.2753C98.6144 29.203 98.2538 29.016 97.9696 28.7381C97.6853 28.4601 97.4903 28.1039 97.4092 27.7147C97.3282 27.3255 97.3648 26.9209 97.5145 26.5527C97.6642 26.1844 97.9202 25.869 98.2498 25.6467C98.5793 25.4244 98.9676 25.3052 99.3652 25.3044C99.6269 25.2969 99.8874 25.3422 100.131 25.4375C100.375 25.5329 100.597 25.6763 100.784 25.8594C100.972 26.0424 101.12 26.2612 101.221 26.5028C101.322 26.7444 101.373 27.0039 101.371 27.2657Z" />
      <path d="M92.2738 26.2367C92.0312 26.1705 91.782 26.1319 91.5309 26.1215C91.1594 26.1215 90.9365 26.2515 90.9365 26.4336C90.9365 26.6156 91.2188 26.7456 91.3748 26.805L91.6423 26.8867C91.8923 26.9406 92.1171 27.0767 92.2807 27.2733C92.4444 27.4699 92.5374 27.7157 92.5449 27.9714C92.5449 28.926 91.7054 29.2455 90.9737 29.2455C90.6282 29.2432 90.2837 29.2084 89.9447 29.1415V28.2537C90.2381 28.3401 90.5416 28.3876 90.8474 28.3948C91.3228 28.3948 91.542 28.2574 91.542 28.0234C91.542 27.7894 91.3526 27.7188 91.1148 27.6519L90.9105 27.5851C90.3756 27.4179 89.9336 27.1059 89.9336 26.4707C89.9336 25.7612 90.4648 25.2858 91.3451 25.2858C91.6594 25.2902 91.9721 25.3301 92.2775 25.4046L92.2738 26.2367Z" />
      <defs>
      <linearGradient id="paint0_linear" x1="21.4097" y1="8.26027" x2="4.94289" y2="13.782" gradientUnits="userSpaceOnUse">
      <stop stopColor="#5CEE64"/>
      <stop offset="1" stopColor="#00BBFF"/>
      </linearGradient>
      <radialGradient id="paint1_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.0539 12.561) rotate(-121.424) scale(10.6601 9.04208)">
      <stop stopColor="#007383"/>
      <stop offset="0.407867" stopColor="#00717E" stopOpacity="0.75"/>
      <stop offset="0.874482" stopColor="#007281" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint2_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(17.173 10.5892) rotate(-127.347) scale(8.7122 5.75938)">
      <stop stopColor="#218970"/>
      <stop offset="0.459527" stopColor="#267E6A"/>
      <stop offset="1" stopColor="#026C51" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint3_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(9.43 13.0617) rotate(-107.836) scale(7.053 3.74169)">
      <stop stopColor="#178697"/>
      <stop offset="0.407867" stopColor="#17838F" stopOpacity="0.79"/>
      <stop offset="0.874482" stopColor="#007281" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint4_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12.6297 13.0241) rotate(-121.424) scale(17.9809 5.60414)">
      <stop stopColor="#25342F" stopOpacity="0.3"/>
      <stop offset="1" stopColor="#25342F" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint5_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(15.8897 10.7852) rotate(84.9882) scale(6.7331 3.90349)">
      <stop stopColor="#056D4F"/>
      <stop offset="0.232593" stopColor="#056C4E" stopOpacity="0.75"/>
      <stop offset="0.598902" stopColor="#056C4E" stopOpacity="0.300824"/>
      <stop offset="0.874482" stopColor="#056C4F" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint6_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(16.1942 -0.102187) rotate(56.4937) scale(7.96654 4.21576)">
      <stop stopColor="#22B67B"/>
      <stop offset="0.407867" stopColor="#24BE82"/>
      <stop offset="1" stopColor="#24BE82" stopOpacity="0.07"/>
      </radialGradient>
      <linearGradient id="paint7_linear" x1="-5.79159" y1="11.8079" x2="5.92955" y2="11.9065" gradientUnits="userSpaceOnUse">
      <stop stopColor="#09F8FF"/>
      <stop offset="1" stopColor="#0BF7F9" stopOpacity="0"/>
      </linearGradient>
      <radialGradient id="paint8_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-3.27352 1.4827) rotate(49.021) scale(10.4336 10.4425)">
      <stop stopColor="#09F8FF"/>
      <stop offset="1" stopColor="#0BF7F9" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint9_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(5.19272 -3.72097) rotate(47.3777) scale(21.1748 21.1856)">
      <stop stopColor="#00B8FF"/>
      <stop offset="0.745423" stopColor="#00B9FC" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint10_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.32654 12.0019) rotate(-107.836) scale(10.1249 7.14388)">
      <stop stopColor="#00BCFF" stopOpacity="0.76"/>
      <stop offset="0.57273" stopColor="#00BAFC" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint11_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(22.0331 8.16428) rotate(-130.177) scale(7.21066 5.06823)">
      <stop stopColor="#5FEC50"/>
      <stop offset="1" stopColor="#3CC08E" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint12_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.9873 12.7664) rotate(-78.8553) scale(2.16492 1.52853)">
      <stop stopColor="#28855B"/>
      <stop offset="1" stopColor="#14795C" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint13_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.8807 12.6072) rotate(-77.6157) scale(1.78506 2.54567)">
      <stop stopColor="#28855B"/>
      <stop offset="1" stopColor="#14795C" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint14_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.0529 9.39453) rotate(-133.426) scale(5.9773 4.20528)">
      <stop stopColor="#5FF05B"/>
      <stop offset="0.813285" stopColor="#5DE955" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint15_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(21.1619 11.0937) rotate(36.8427) scale(5.33572 7.37205)">
      <stop stopColor="#123AA8"/>
      <stop offset="0.966119" stopColor="#123AA8" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint16_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.8237 11.6867) rotate(8.30215) scale(6.3439 9.26978)">
      <stop stopColor="#123AA8"/>
      <stop offset="0.592487" stopColor="#123AA8" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint17_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.3387 12.4566) rotate(24.1836) scale(5.40105 9.48433)">
      <stop stopColor="#123AA8"/>
      <stop offset="0.756788" stopColor="#123AA8" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint18_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(19.7021 12.7708) rotate(95.9323) scale(9.53426 5.33984)">
      <stop stopColor="#123AA8"/>
      <stop offset="0.592487" stopColor="#123AA8" stopOpacity="0"/>
      <stop offset="1" stopColor="#123AA8" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint19_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(21.1619 8.56248) rotate(93.7494) scale(11.4725 4.18555)">
      <stop stopColor="#123AA8"/>
      <stop offset="0.884611" stopColor="#123AA8" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint20_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.86928 10.9476) rotate(84.714) scale(6.0884 3.29688)">
      <stop stopColor="#123AA8"/>
      <stop offset="0.884611" stopColor="#123AA8" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint21_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.86483 11.1114) rotate(87.1887) scale(6.35336 5.36263)">
      <stop stopColor="#123AA8"/>
      <stop offset="0.884611" stopColor="#123AA8" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint22_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(9.34276 17.081) rotate(-22.7152) scale(7.21963 13.8952)">
      <stop stopColor="#123AA8"/>
      <stop offset="0.708977" stopColor="#123AA8" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint23_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.76526 9.55813) rotate(-159.94) scale(4.78651 8.05849)">
      <stop stopColor="#1A3DA0"/>
      <stop offset="0.708977" stopColor="#123AA8" stopOpacity="0"/>
      </radialGradient>
      <radialGradient id="paint24_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(2.54067 11.1556) rotate(16.4007) scale(9.13706 9.05368)">
      <stop stopColor="#1E3882"/>
      <stop offset="0.761404" stopColor="#123AA8" stopOpacity="0"/>
      </radialGradient>
      <linearGradient id="paint25_linear" x1="-0.331541" y1="6.20397" x2="8.57129" y2="12.014" gradientUnits="userSpaceOnUse">
      <stop offset="0.229329" stopColor="#1DC4FF"/>
      <stop offset="1" stopColor="#1CC1FF" stopOpacity="0"/>
      </linearGradient>
      <radialGradient id="paint26_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(-3.27407 1.48228) rotate(49.021) scale(10.4336 10.4425)">
      <stop stopColor="#09F8FF"/>
      <stop offset="1" stopColor="#0BF7F9" stopOpacity="0"/>
      </radialGradient>
      <linearGradient id="paint27_linear" x1="0.167041" y1="6.49161" x2="2.64631" y2="8.78972" gradientUnits="userSpaceOnUse">
      <stop stopColor="#26FBFF"/>
      <stop offset="0.637346" stopColor="#0BF7F9" stopOpacity="0"/>
      </linearGradient>
      <linearGradient id="paint28_linear" x1="-0.505157" y1="8.20858" x2="2.99361" y2="8.46382" gradientUnits="userSpaceOnUse">
      <stop stopColor="#26FBFF"/>
      <stop offset="0.637346" stopColor="#0BF7F9" stopOpacity="0"/>
      </linearGradient>
      <radialGradient id="paint29_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.32622 12.002) rotate(-107.836) scale(10.1249 7.14388)">
      <stop stopColor="#00BCFF" stopOpacity="0.76"/>
      <stop offset="0.57273" stopColor="#00BAFC" stopOpacity="0"/>
      </radialGradient>
      </defs>
    </svg>
  );
}
